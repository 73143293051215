<template>
    <section class="px-4 space-y-4 text-center flex justify-center flex-col grow">
        <NuxtImg class="w-full h-auto" src="/images/404.png" alt="Page not found" />
        <p class="text-sm text-brand-neutral-7">
            Ops! Parece que algo deu errado. Volte para a
            <NuxtLink class="text-brand-primary-1 font-bold" to="/"
                >página inicial</NuxtLink
            >
            e tente novamente.
        </p>
    </section>
</template>
<script setup lang="ts">
definePageMeta({
    layout: "authenticated",
    middleware: ["authenticate-user"],
});

useHead({
    title: "Página não encontrada",
    meta: [
        {
            name: "description",
            content:
                "Ops! Parece que algo deu errado. Volte para a página inicial e tente novamente.",
        },
    ],
});
</script>
